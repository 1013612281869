import reportWebVitals from './reportWebVitals'
import { createRoot } from 'react-dom/client'
import 'bootstrap/dist/css/bootstrap-grid.min.css'
import 'bootstrap/dist/css/bootstrap-utilities.min.css'
import 'react-quill/dist/quill.snow.css'
import App from './App'
import { Amplify } from 'aws-amplify'
import awsConfig from 'aws-exports'

  const productionRedirectSignIn = `${window.location.origin}/`
  const productionRedirectSignOut = `${window.location.origin}/login/`

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: productionRedirectSignIn,
    redirectSignOut: productionRedirectSignOut,
  },
}
Amplify.configure(updatedAwsConfig)

const domNode = document.getElementById('root')
const root = createRoot(domNode)
root.render(<App />)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
